@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import '../../styles/variables/breakpoints';

@mixin legend($theme) {
  font-family: $default-font;
  font-size: 1.2rem;
  text-align: center;
  color: get-color($theme, "gray");

  .border-legend {
    display: flex;
    align-self: center;
    border: 1px dotted get-color($theme, "gray-light-medium");
    color: get-color($theme, "gray-medium");
    margin: 0;

    @include medium-screen;
    @include small-screen {
      flex-direction: column;
    }

    @include large-screen;
    @include extra-large-screen {
      flex-direction: row;
    }

    .group-legend {
      max-width: 250px;
      padding: 0.5% 0.5%;
      text-align: start;
  
      @include medium-screen;
      @include small-screen {
        width: 100px;
      }
  
      @include large-screen;
      @include extra-large-screen {
        width: 250px;
      }

      .color-legend {
        width: 20px;
        height: 20px;
        border: 1px solid get-color($theme, "gray");
        color: get-color($theme, "gray-medium");
        padding: 1%;
        text-align: center;
      }
    }
  }

  .text-legend {
    font-family: $default-font;
    font-size: 0.8rem;
    color: get-color($theme, "gray");
  }
}

  .app-container {
    &-light {
      .legend {
        @include legend('light');
      }
    }
    &-dark {
      .legend {
        @include legend('dark');
      }
    }
  }