@import '../variables/colors';

.MuiIconButton-root {
  background-color: $accent;
}

@mixin MuiButtonBase-root($theme) {
  .MuiButton-containedPrimary {
    background-image: linear-gradient(to right, get-color($theme, 'accent') 10%, get-color($theme, 'accent-dark') 100%);
    .MuiButton-label {
      color: get-color($theme, 'white');
    }
  }
  .MuiButton-containedSecondary {
    background-image: linear-gradient(to right, get-color($theme, 'accent') 10%, get-color($theme, 'accent') 100%);
    .MuiButton-label {
      color: get-color($theme, 'white');
    }
  }
}

.app-container {
  &-light {
    @include MuiButtonBase-root('light');
  }
  &-dark {
    @include MuiButtonBase-root('dark');
  }
}
