@import '../../../styles/variables/colors';

@mixin granja-filter-card($theme) {
  margin: 1%;

  .MuiFormControl-root {
    .Mui-focused {
      color: $accent;
      border-color: $accent;
    }
  }
  .css-1sumxir-MuiFormLabel-root-MuiInputLabel-root {
    color: get-color($theme, 'accent-dark');
    font-size: 1.3rem;
    font-weight: bold;
  }

  .css-wb57ya-MuiFormControl-root-MuiTextField-root {
    background-color: get-color($theme, 'white');
    .MuiAutocomplete-inputRoot .MuiAutocomplete-input {
      color: get-color($theme, 'gray-medium');
      font-size: 1.5rem;
    }
  }

  .css-1d3z3hw-MuiOutlinedInput-notchedOutline {
    border: none;
    border-radius: 5px;
    box-shadow: 0px 0px 5px get-color($theme, 'shadow-light');
  }
}

.app-container {
  &-light {
    .granja-filter-card {
      @include granja-filter-card('light');
    }
  }
  &-dark {
    .granja-filter-card {
      @include granja-filter-card('dark');
    }
  }
}
