@import '../../styles/variables/_colors';
@import '../../styles/variables/fonts';

@mixin info-modal($theme) {
  .MuiDialog-paperWidthSm {
    max-width: 1000px;
  }
  .MuiDialogContent-dividers {
    padding: 5px 10px;
    .MuiDialogContentText-root {
      display: flex;
      padding: 5px 10px;
      font-size: 1.2rem;
        svg {
          margin-right: 0.5rem;
          font-size: 1.4rem;
        }
    }
  }
  .MuiButton-root {
    color: rgba(0, 0, 0, 0.54);
    font-family: $default-font;
  }
}

.info-modal {
  @include info-modal('light');
  &-light {
    @include info-modal('light');
  }
  &-dark {
    @include info-modal('dark');
  }
}

.info-modal-open {
  animation: openModal 300ms;
}
.info-modal-closed {
  animation: closeModal 300ms;
}

.drawer-spacing {
  min-height: 80px;
}
