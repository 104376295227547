@import '../../../styles/variables/colors';
@import '../../../styles/variables/_fonts';

@mixin analise-crescimento-table($theme) {
    background-color: get-color($theme, 'white');
    padding: 1%;
    .title {
        font-size: 1.8em;
        margin-bottom: 1%;
        font-family: $default-font;
        color: get-color($theme, 'gray');
    }
    .MuiTableCell-head {
        font-family: $default-font;
        color: get-color($theme, 'gray');
        font-weight: bolder;
        .MuiTableCell-root {
            font-size: 1rem;
        }
    }
    .MuiTableBody-root {
        background-color: get-color($theme, 'white');
        .MuiTableCell-body {
            color: get-color($theme, 'gray-medium');
        }
    }
    .MuiTableCell-alignCenter{
        line-height: 1em;
    }
}

.app-container {
    &-light {
        .analise-crescimento-table {
            @include analise-crescimento-table('light');
        }
    }

    &-dark {
        .analise-crescimento-table {
            @include analise-crescimento-table('dark');
        }
    }
}
