@import 'variables/breakpoints';

@import 'shared/auth';
@import 'shared/app';
@import 'shared/card';
@import 'shared/card-list';
@import 'shared/button';

html {
  font-family: 'Bahnschrift', sans-serif;
}
