@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';
@import '../../../styles/shared/app';

@mixin scatter-chart($theme) {
  display: flex;
  flex: 1;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 325px;
  // width: 50%;

  .tooltip-container {
    background-color: get-color($theme, 'ghost-white');
    border: 1px solid get-color($theme, 'gray');
    border-radius: 5px;
    padding: 5px;
  }

  .chart-responsive-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  .chart-value-text {
    font-weight: bold;
    font-size: 1rem;
    font-family: $default-font;
  }
}
.app-container {
  &-light {
    .scatter-chart {
      @include scatter-chart('light');
    }
  }
  &-dark {
    .scatter-chart {
      @include scatter-chart('dark');
    }
  }
}
