@import '../variables/breakpoints';

@keyframes openSidebar {
  from {
    width: $sidebar-min-width;
  }

  to {
    width: $sidebar-open-width;
  }
}

@keyframes closeSidebar {
  from {
    width: $sidebar-open-width;
  }

  to {
    width: $sidebar-min-width;
  }
}

@keyframes openModal {
  from {
    height: $modal-min-height;
  }

  to {
    height: $modal-open-height;
  }
}

@keyframes closeModal {
  from {
    height: $modal-open-height;
  }

  to {
    height: $modal-min-height;
  }
}
