@import '../../../styles/variables/_fonts';

@mixin aviary-weighing-body($theme) {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  width: 100%;
  justify-content: center;

  .MuiPaper-root {
    .MuiTable-root {
          .MuiTableHead-root {
            .MuiTableCell-root {
              div {
                font-weight: bold;
                color: gray;
              }
            }
        }
    } 
}

  .input-margin {
    margin: 1rem;
  }

  .toolbar {
    display: flex;
    padding: 0px 10px;
    align-items: baseline;
  }
}

.app-container {
  &-light {
    .aviary-weighing-body {
      @include aviary-weighing-body('light');
    }
  }
  &-dark {
    .aviary-weighing-body {
      @include aviary-weighing-body('dark');
    }
  }
}
