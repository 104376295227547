@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';

@mixin legend($theme) {
.legend {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  .border-legend {
    display: flex;
    justify-content: center;
    flex-direction: row;
    border: 1px dotted get-color($theme, "gray-light-medium");
    margin: 30px;
  }
  .group-legend {
    display: flex;
    padding: 20px 25px;
    align-items: center;
    flex-direction: row;
  }
  .color-legend {
    width: 10px;
    height: 10px;
    margin-right: 10px;
    border: 1px solid get-color($theme, "gray");
  }
  .text-legend {
    font-family: $default-font;
    font-size: 0.8rem;
    color: get-color($theme, "gray");
  }
}

  .app-container {
    &-light {
      .legend {
        @include legend('light');
      }
    }
    &-dark {
      .legend {
        @include legend('dark');
      }
    }
  }