@import '../../../styles/variables/colors';

.div {
  display: flex;
  flex-direction: column-reverse;
  margin-bottom: 15px;
  width: 100%;
  min-width: 200px;

  .input[value=''] {
    height: 100%;
  }

  label {
    margin-bottom: 1rem;
  }
  
  .inputLoading,
  .input {
    display: flex;
    align-items: center;
    flex: 1;
    width: 100%;
    max-height: 50px;
    min-height: 50px;
    outline: none;
    border-radius: 6px;
    min-width: 150px;
  }

  .inputLoading {
    height: 50%;
    border: none;
  }

  &-error {
    @extend .div;
    color: $error;
    label { color: $accent; }  
    span {
      margin-top: 1%;
      color: $gray-medium ;
    }  
    .input {
      border: 2px solid $error !important;
      cursor: pointer;
    }
  }
}
