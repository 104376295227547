
@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';
@import '../../styles/variables/breakpoints';

@mixin weekly-planning-container($theme) {
  display: flex;
  width: 100vw;
  padding: 0.5%;
  flex-direction: column;

  .white-row {
    background-color: get-color($theme, 'white') !important;
  }

  .gray-row {
    // LOTS MOVED IN THE WEEK
    background-color: get-color($theme, 'gray-light') !important;
  }

  .blue-row {
    // LOTS REMOVED FROM THE WEEK INTO HOUSING
    background-color: get-color($theme, 'blue-light-opacity') !important;
  }

  .yellow-row {
    // LOTS FROM HOUSINGS
    background-color: get-color($theme, 'yellow-light-opacity') !important;
  }
  
  .red-row {
    // LOTS FROM OTHERS INTEGRATORS
    background-color: get-color($theme, 'accent-35') !important;
  }

  .error-cell {
    // LOTS FROM OTHERS INTEGRATORS
    border: 2px solid get-color($theme, 'error') !important;
  }

  &-tabs {
    .MuiTabs-centered {
      justify-content: flex-start;
    }
  }

  &-tab-panel {
    display: flex;
    flex-direction: column;

    .summary-accordion {
      width: 95vw;
      &-details {
        padding: 0.2%;
      }
    }

    &-section-housing {
      display: flex;
      position: relative;
      flex-direction: row;
      width: auto;
      height: auto;
      z-index: 2;
      margin-bottom: 1%;
      margin-top: 2%;

      .housing-accordion {
        width: 95vw;
        &-details {
          padding: 0.2%;
        }
      }
    }

    .table-menu-buttons {
      display: flex;
      flex-direction: row;
      margin-bottom: 0.5%;
      justify-content: space-between;

      @include medium-screen;
      @include small-screen {
        flex-direction: column;
      }

      .initial {
        align-self: flex-end;
      }
      
      .save-button {
        align-self: flex-end;
        .MuiSvgIcon-root {
          color: get-color($theme, accent-dark);
          font-size: 2rem;
        }
      }
    }

    &-section-planning {
      display: flex;
      flex-direction: column;
      width: auto;
      height: auto;

      .planning-accordion {
        width: 95vw;
        &-details {
          padding: 0.2%;
        }
      }

      .dotted-line {
        border: none;
        border-top: 1px dotted grey;
        margin: 1% 0;
      }

      h2 {
        margin: 1% 0;
      }

      #running-text {
        margin-left: 0.5%;
        color: get-color($theme, 'accent');
      }
  
      .run-poultrytrader-button {
        display: flex;
        align-self: flex-end;
        margin-top: 2%;
      }
    }
  } 
}

.app-container {
    &-light {
        .weekly-planning-container {
            @include weekly-planning-container('light');
        }
    }

    &-dark {
        .weekly-planning-container {
            @include weekly-planning-container('dark');
        }
    }
}