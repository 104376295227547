@import '../../styles/variables/_colors';
@import '../../styles/variables/fonts';


.user-settings {
  .MuiPaper-root {
    width: 30%;
    min-width: 350px;
  }
  .title {
    background-color: $ghost-white;
    font-size: 1.8em;
    font: $default-font;
    color: $gray;
  }
  .subtitle {
    font: $default-font;
    color: $accent-dark;
    margin: 2% 3% 2% 4%;
  }
  .MuiDialogActions-root {
    margin: 1%;
    background-color: $ghost-white;
    .MuiButton-label {
      color: $accent-dark;
    }
  }
  .form {
    .input {
      border: none;
      color: $gray;
      border-bottom: 1px solid $gray;
    }
    label {
      margin-bottom: 2%;
      color: $gray;
    }
  }
}
