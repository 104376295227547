@import '../../styles/variables/breakpoints';
@import '../../styles/variables/colors';
@import '../../styles/variables/fonts';

@mixin analise-crescimento-container($theme) {
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: 0.5fr 0.5fr;
  column-gap: 20px;
  row-gap: 10px;
  grid-template-rows: auto;
  padding: 2%;
  @include small-screen {
    display: flex;
    flex-direction: column;
  }

  h1 {
    grid-column: span 4;
    display: flex;
    margin-bottom: 8px;
    font-size: 25px;
    font-family: $default-font;
    color: get-color($theme, 'gray');
  }

  h2 {
    grid-column: span 2;
    display: flex;
    justify-content: center;
    margin-top: 2%;
    margin-bottom: 8px;
    font-size: 20px;
    font-family: $default-font;
    color: get-color($theme, 'gray');
  }

  span.unity {
    font-size: 0.7rem;
    font-family: $default-font;
    color: get-color($theme, 'gray');
  }
  .analise-crescimento-table{
    grid-column: span 4;
  }

  span.txt{
    grid-column: span 4;
    display: flex;
    justify-content: center;
  }
}
  
  .app-container {
    &-light {
      .analise-crescimento-container {
        @include analise-crescimento-container('light');
      }
    }
    &-dark {
      .analise-crescimento-container {
        @include analise-crescimento-container('dark');
      }
    }
  }