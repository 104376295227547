@import '../../../styles/variables/breakpoints';
@import '../../../styles/variables/colors';
@import '../../../styles/variables/fonts';

@mixin chart-list($theme) {
  width: 100%;
  // border-radius: 10px;
  // box-shadow: 0px 0px 5px get-color($theme, 'shadow');
  h1 {
    margin-bottom: 5%;
    font-size: 1em;
  }
  h3,
  h1 {
    display: flex;
    justify-content: center;
  }

  // display: flex;
  // flex: 1;
  // position: relative;
  // flex-direction: column;
  // align-items: center;
  // justify-content: center;
  // width: 100%;

  .filters-group-chart {
    display: flex;
    padding-top: 1rem;
    padding-right: 20px;
    justify-content: flex-end;
    @include small-screen{
     flex-direction: column;
     align-items: center; 
    }
  }

  .lot-button-row {
    margin: 0.7rem;
    display: flex;
    width: 100%;
    // position: relative;
    justify-content: flex-start;
    padding: 0.5rem 0rem;
  }

  .data-type-button {
    // max-width: 70%;
    // box-shadow: 0px 0px 5px get-color($theme, 'shadow');
    margin-right:50px;
    border: 1px solid get-color($theme, "border-gray");
    background-color: get-color($theme, "white");
    &:hover {
      // background-color: get-color($theme, 'white'); //get-color($theme, 'gray');
      box-shadow: 0px 0px 5px get-color($theme, "shadow");
    }
  }
  .text-button {
    color: get-color($theme, "gray-medium");
  }

  .charts-container {
    display: flex;
    flex-direction: row;
    // flex: 1;
    flex-wrap: wrap;
    width: 100%;
    justify-content: space-evenly;
  }

  .charts {
    display: flex;
    min-width: 50%;
    flex-direction: column;
    @include large-screen {
      min-width: 33%;
    }
    @include small-screen {
      min-width: 100%;
    }

    .chart-title {
      padding-top: 15px;
      color: get-color($theme, "gray");
      font-size: 1.2rem;
      font-weight: bold;
      font-family: $default-font;
    }
  }
  
  .select-root {
    color: get-color($theme, 'gray');
    border: 1px solid get-color($theme, 'gray');
    border-radius: 0.3rem;
    text-align: left;
    padding-left: 5px;
    margin: 5px;
    font-size: 1rem;
    @include small-screen {
      font-size: 0.9rem;
    }
  }
  .select-icon {
    // background-color: sienna;
    padding: 0px 5px;
  }
}

.app-container {
  &-light {
    .chart-list {
      @include chart-list("light");
    }
  }
  &-dark {
    .chart-list {
      @include chart-list("dark");
    }
  }
}
