@import '../../styles/variables/colors';

@mixin home-container($theme) {
  display: flex;
  flex: 1;
  flex-direction: column;

  .container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 1rem;
    padding: 1rem;
  }
  
  .row {
    /* Fill the whole grid row */
    grid-column: 1 / -1;
  }
  
  .card-container {
    --padding: 0.8em;
  
    display: flex;
    flex-direction: column;
    font-size: 1.7rem;
    padding: var(--padding);

    .card-title {
      padding: 1%;
    }
  }
  
  .card > p {
    margin: 0.3em;
  }
  
  .card-container > div {
    display: flex;
    flex-direction: row;
    height: 100%;
    gap: var(--padding);
  }
  
  .innerCard {
    /* Cards same width */
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0;
  
    display: flex;
    flex-direction: column;
    width: max-content;
    border: none;
    border-radius: 0.2em;
    background-color: get-color($theme, 'very-pale-orange');
    box-shadow: 0px 0px 5px get-color($theme, 'shadow');
  }
  
  .innerCard > p {
    margin: 0;
    padding: 0.6rem;
    text-align: center;
    background-color: get-color($theme, 'very-pale-orange');
    box-shadow: 0px 0px 5px get-color($theme, 'shadow');
  }
  
  .innerCard > div {
    padding: 0.1em;
    font-size: 1.5em;
    margin: 0.9em auto;
    text-align: center;
    color: get-color($theme, 'red-dark');
  }
  
  .innerCard > div > small {
    font-weight: normal;
    font-size: 0.6em;
  }
  
  /* Temp */
  .graph {
    min-height: 200px;
    justify-content: center;
    align-items: center;
  }
  
  .graph p {
    margin: auto;
    transform: rotate(-45deg);
  }
}

.app-container {
  &-light {
    .home-container {
      @include home-container('light');
    }
  }
  &-dark {
    .home-container {
      @include home-container('dark');
    }
  }
}
