@import '../../../styles/variables/colors';
@import '../../../styles/variables/breakpoints';

@mixin formSelect($theme) {  
  .MuiInputLabel-formControl {
    color: get-color($theme, 'black');
    position: relative;
    margin-bottom: 1.5rem;
  }
  &-error {
    .MuiOutlinedInput-input {
      border: 2px solid get-color($theme, 'error') !important;
    }
    .MuiInputLabel-formControl  { 
      color: get-color($theme, 'accent'); 
    }
      .error { 
        margin-top: 1rem;
      }
    }
}

.app-container {
  &-light {
    .formSelect {
      @include formSelect('light');
    }
  }
  &-dark {
    .formSelect {
      @include formSelect('dark');
    }
  }
}