@import "../variables/colors";
@import "../variables/breakpoints";
@import "../variables/fonts";

$icon-container-width: 100px;
$icon-container-height: 80px;

@mixin card-list-container($theme) {
  position: relative;
  display: flex;
  margin-top: 35px;
  border-radius: 10px;
  padding: 20px;
  min-height: 620px;
  width: 85%;
  align-self: center;
  box-shadow: 0px 0px 5px get-color($theme, "shadow");
  background-color: get-color($theme, "background-card");
  justify-content: center;
  @include large-screen {
    width: 45%;
    min-width: 600px;
    min-height: 380px;
  }

  .card-list-body {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .card-icon-container {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: 20px;
    left: 20px;
    box-shadow: 0px 0px 5px get-color($theme, "shadow");
    transform: translateY(-50%);
    width: $icon-container-width;
    height: $icon-container-height;
    border-radius: 5px;
    background-color: get-color($theme, "white");
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  }

  .card-select-container {
    width: 150px;
    margin: 1rem;
    margin: 0;
    margin-right: 20px;
    color: get-color($theme, "gray-medium");
    text-align: center;
    @include small-screen{
      margin-right: 0px;
    }
  }
  .select-group {
    margin-right: 0px;
    align-self: center;
    display: flex;
    flex-direction: row;
   @include large-screen{
      // margin-right: 30px;
    }
  }
  
  .card-subcontent {
    display: flex;
    flex: 1;
    // align-content: flex-start;
    align-items: stretch;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    width: 100%;
    margin-top: 10px;
    @include large-screen {
      margin-top: 0px;
      margin-right: 20px;
    }
  }

  .card-content {
    display: flex;
    flex: 1;
    align-content: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    @include large-screen {
      flex-direction: row;
    }
  }

  .card-chart-container {
    position: relative;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: flex-end;
    height: 100%;
    width: 100%;
  }

  .chart-responsive-container {
    position: absolute;
    left: 0;
    right: 0;
  }

  .chart-title-text {
    color: get-color($theme, "accent");
    font-size: 1rem;
    z-index: 2;
    font-family: $default-font;
    text-align: center;
  }

  .chart-value-text {
    font-size: 0.9rem;
    font-family: $default-font;
    @include small-screen {
      font-size: 0.8rem;
    }
  }

  .chart-tick-text {
    font-family: $default-font;
    fill: get-color($theme, "gray");
    text-transform: capitalize;
    font-size: 1rem;
    @include small-screen {
      font-size: 0.8rem;
    }
  }

  .card-title-text {
    font-family: $default-font;
    font-size: 2.5rem;
    color: get-color($theme, "accent");
    width: 90%;
    max-height: 90%;
    line-height: 1em;
    text-align: center;
    // height: $icon-container-width;
    @include small-screen {
      font-size: 1.1rem;
    }
  }
  .card-title-small-text {
    font-family: $default-font;
    font-size: 0.8rem;
    color: get-color($theme, "accent");
    width: 90%;
    margin-top: 0.2rem;
    max-height: 90%;
    line-height: 1em;
    text-align: center;
    // height: $icon-container-width;
    @include small-screen {
      font-size: 0.9rem;
    }
  }

  .card-title-container {
    display: flex;
    flex-wrap: wrap;
    padding-top: 12px;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    @include large-screen {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
  .card-footer {
    display: flex;
    // flex: 1;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    // min-height: 1.5rem;
    align-self: stretch;
    @include large-screen{
      flex-direction: row;
      align-items: flex-end;
    }
  }

  .card-footer-text {
    font-family: $default-font;
    display: flex;
    flex-direction: row;
    margin: 15px 0px;
    font-size: 0.9rem;
    color: get-color($theme, "gray");
    text-align: left;
    @include small-screen {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  .card-title {
    font-family: $default-font;
    font-size: 1.8rem;
    text-align: left;
    padding-left: $icon-container-width + 20px;
    color: get-color($theme, "gray");
    @include small-screen {
      font-size: 1.5rem;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .table-cell {
    padding: 10px 0px;
    @include large-screen {
      padding: 10px 15px;
    }
  }

  .title-cell{
    color: get-color($theme,"gray");
  }

  .body-cell{
    color: get-color($theme,"gray-medium");
  }

  .info-button {
    height: 50px;
    width: 200px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px get-color($theme, "shadow");
    text-transform: none;
  }

  .text-info-button {
    color: get-color($theme, "accent-light");
  }
}

.app-container {
  &-light {
    .card-list-container {
      @include card-list-container("light");
    }
  }
  &-dark {
    .card-list-container {
      @include card-list-container("dark");
    }
  }
}
