$accent-dark: #990000;
$accent: #D1422A;
$accent-70: rgba(209, 66, 42, 0.7);
$accent-35: rgba(209, 66, 42, 0.35);
$black: #000;
$black-15:rgba(0, 0, 0, 0.15);
$black-10:rgba(0, 0, 0, 0.10);
$ghost-white: #f8f8f8;
$gray: #939597;
$gray-medium: #4d4d4d;
$light-orange:#ff924d;
$very-pale-orange:#ffeeeb;
$border-gray: rgba(147, 149, 151, 0.3);
$red-dark: #922700;
$white: #fff;
$yellow: yellow;
$yellow-light-opacity: rgba(255, 255, 0, 0.2);
$green: green;
$blue: rgb(40, 121, 172);
$blue-light-opacity: rgba(40, 121, 172, 0.2);
$info: rgb(19, 54, 142);
$info-light-opacity:rgba(19, 54, 142, 0.2);
$warning:rgb(245, 223, 77);
$warning-light-opacity:rgba(245, 223, 77, 0.2);
$success: rgb(61, 129, 22);
$success-light-opacity: rgba(61, 129, 22, 0.2);
$green-dark: #295e0a;
$accent-light: #e8af30;
$accent-light-opacity: rgba(#e8af30, 0.8);
$error: #de2329;
$error-light-opacity: rgba(222, 35, 41, 0.2);
$white-smoke: #f5f5f5;
$gray-light: #eee;
$gray-light-medium: #cbcbcb;
$gray-dark-light: #8a8a8a;
$gray-dark: rgb(25, 25, 25);
$info-modal-bg: rgba(25, 25, 25, 0.5);
$title: #525252;
$pink-light: rgba(255,100,0,.1);

$color-collection: (
  'accent': (
    'light': $accent,
    'dark': $accent
  ),
  'accent-70': (
    'light': $accent-70,
    'dark': $accent-70
  ),
  'accent-35': (
    'light': $accent-35,
    'dark': $accent-35
  ),
  'accent-dark': (
    'light': $accent-dark,
    'dark': $accent-dark
  ),
  'black': (
    'light': $black,
    'dark': $black
  ),
  'black-15': (
    'light': $black-15,
    'dark': $black-15
  ),
  'background': (
    'light': $ghost-white,
    'dark': $ghost-white
  ),
  'background-card': (
    'light': $white,
    'dark': $white
  ),
  'ghost-white': (
    'light': $ghost-white,
    'dark': $ghost-white
  ),
  'gray': (
    'light': $gray,
    'dark': $gray
  ),
  'gray-medium': (
    'light': $gray-medium,
    'dark': $gray-medium
  ),
  'light-orange': (
    'light': $light-orange,
    'dark': $light-orange
  ),
  'very-pale-orange': (
    'light': $very-pale-orange,
    'dark': $very-pale-orange
  ),
  'red-dark': (
    'light': $red-dark,
    'dark': $red-dark
  ),
  'shadow': (
    'light': $black-15,
    'dark': $black-15
  ),
  'shadow-light': (
    'light': $black-10,
    'dark': $black-10
  ),
  'yellow': (
    'light': $yellow,
    'dark': $yellow
  ),
  'yellow-light-opacity': (
    'light': $yellow-light-opacity,
    'dark': $yellow-light-opacity
  ),
  'white': (
    'light': $white,
    'dark': $white
  ),
  'accent-bg': (
    'light': $accent,
    'dark': $black
  ),
  'accent-light': (
    'light': $accent-light,
    'dark': $accent-light
  ),
  'accent-light-opacity': (
    'light': $accent-light-opacity,
    'dark': $accent-light-opacity
  ),
  'black-bg': (
    'light': $black,
    'dark': $black
  ),
  'error': (
    'light': $error,
    'dark': $error
  ),
  'foreground': (
    'light': $black,
    'dark': $white
  ),
  'gray-light-medium': (
    'light': $gray-light-medium,
    'dark': $gray-light-medium
  ),
  'gray-bg': (
    'light': $gray,
    'dark': $white
  ),
  'gray-dark-light': (
    'light': $gray-dark-light,
    'dark': $gray-dark-light
  ),
  'gray-dark': (
    'light': $gray-dark,
    'dark': $gray-dark
  ),
  'gray-light': (
    'light': $gray-light,
    'dark': $gray-light
  ),
  'gray-light-bg': (
    'light': $gray-light,
    'dark': $gray-dark
  ),
  'info-modal-bg': (
    'light': $info-modal-bg,
    'dark': $info-modal-bg
  ),
  'shadow-dark': (
    'light': #888,
    'dark': #6d6d6d
  ),
  'border-gray': (
    'light': $border-gray,
    'dark': $border-gray
  ),
  'white-smoke': (
    'light': $white-smoke,
    'dark': $white-smoke
  ),
  'white-bg': (
    'light': $white,
    'dark': $gray-dark
  ),
  'green': (
    'light': $green,
    'dark': $green-dark
  ),
  'blue': (
    'light': $blue,
    'dark': $blue
  ),
  'blue-light-opacity': (
    'light': $blue-light-opacity,
    'dark': $blue-light-opacity
  ),
  'info': (
    'light': $info,
    'dark': $info
  ),
  'warning': (
    'light': $warning,
    'dark': $warning
  ),
  'success': (
    'light': $success,
    'dark': $success
  ),
  'info-light-opacity': (
    'light': $info-light-opacity,
    'dark': $info-light-opacity
  ),
  'warning-light-opacity': (
    'light': $warning-light-opacity,
    'dark': $warning-light-opacity
  ),
  'error-light-opacity': (
    'light': $error-light-opacity,
    'dark': $error-light-opacity
  ),
  'success-light-opacity': (
    'light': $success-light-opacity,
    'dark': $success-light-opacity
  ),
  'title': (
    'light': $title,
    'dark': $title
  ),
  'pink-bg': (
    'light': $pink-light,
    'dark': $pink-light
  )
);

@function get-color($theme, $color) {
  $map: map-get($color-collection, $color);
  @return map-get($map, $theme);
}
