@import '../../styles/variables/colors';
@import '../../styles/variables/breakpoints';
@import '../../styles/variables/fonts';

.MuiPaper-root {
  background-image: none;
  background-color: white
}

@mixin parametrosPO-container($theme) {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 1%;

  .container {
    display: grid;
    padding: 1rem;
    background-color: get-color($theme, 'white');

    .pageTitle {
      display: block;
      margin-bottom: 8px;
      font-size: 25px;
      font-family: $default-font;
      color: get-color($theme, 'gray');
    }

    h1 { 
      display: block;
      font-size: 20px;
      font-family: $default-font;
      color: get-color($theme, 'gray');
      margin: 1% 0%;
      width: 100%;
    }

    .MuiFormControl-root {
      min-width: 150px;
    }

    .parametrosPOForm {
      display: flex;
      flex-wrap: wrap;
      .formSelect-error,
      .formSelect {
        .MuiInputLabel-outlined.MuiInputLabel-shrink {
          transform: none;
          color: get-color($theme, 'gray-medium');
        }
        .MuiSelect-outlined.MuiSelect-outlined {
          background-color: get-color($theme, 'very-pale-orange');
          border: none;
        }
      }
      .div-error,
      .div {
        max-width: 10%;
        margin-right: 1rem;
        .inputLoading,
        .input {
          background-color: get-color($theme, 'very-pale-orange');
          border: none;
          color: get-color($theme, 'gray-medium');
        }
        label {
          color: get-color($theme, 'gray-medium');
        }
      }
      .MuiButtonBase-root {
        .MuiButton-label {
          font-weight: bold;
          color: get-color($theme, 'white');
        }
      }
      .updateButton {
        min-width: 200px;
        margin-top: 2%;
        margin-left: 80%;
        padding: 1em;
        background-image: linear-gradient(to right, get-color($theme, 'accent') 10%, get-color($theme, 'accent-dark') 100%);
        color: get-color($theme, 'white');
        @include medium-screen;
        @include small-screen {
          margin-left: 15%;
        }
      }
    }

    span {
        svg.replicate-icon {
          color: get-color($theme, 'accent-dark');
      }
    }

    .save-button {
      width: 15%;
      margin: 2% 0 0 85%;
      background-color: get-color($theme, 'accent-dark');
      .save-button-text {
        color: get-color($theme, 'white');
      }
    }
  }
}

.app-container {
  &-light {
    .parametrosPO-container {
      @include parametrosPO-container('light');
    }
  }
  &-dark {
    .parametrosPO-container {
      @include parametrosPO-container('dark');
    }
  }
}
